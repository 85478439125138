$xl: 5em;
$md: calc(0.4em + 0.1vw);

@import url('https://fonts.googleapis.com/css?family=Karla|Quantico');

//media queries-------------------------
$phone-tab: '(max-width: 800px)';
$tab: '(max-width: 800px) and (min-height: 900px)';
$phone: '(max-width: 600px)';
$medium: '(min-width: 400px) and (max-width: 840px) and (max-height: 1300px)';
