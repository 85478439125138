#contact{
  @include position(relative, 0%, 0%);
  padding:0;
  margin: 0;
  width: 100%;
  height: 90vh;
  text-align: center;
  transform: translate3d(0,100, 0);
  font-size: calc(1.7em + 2vw);
  @include media($tab){
    height: 100vh;
  }

  h3{
    font-size: $md;
    color: #CA7564;
    margin-top: 5%;
    padding: 0;
  }
  h1{
    margin: -1%;
    padding: 0;
    font-weight: 800;
    color:  #d9d9d9;
    font-family: 'Quantico', Helvetica, sans-serif;
  }

  .wrapper{
    @include display(row, center, center);
    margin-top: calc(13% + 6vw);
    @include media($tab){
      margin-top: 25%;
    }
    @include media($phone-tab){
      margin-top: 25%!important;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 0px 0px;
    }

    .card{
       width: 480px;
       height: auto;
       background: rgba(0,0,0,0.8);
       padding: 20px 0px;
       border: 0.5px solid white;
       z-index: 999!important;
       text-align: center!important;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       flex-wrap: no-wrap;
       transform: translate3d(0,0,0);
      @include media($phone-tab){
        width: auto;
        background: rgba(0,0,0,0.5);
      }


      > a button{
        width: 99%;
        height: auto;
        margin: 0 0;
        padding: 20px;
        font-size: $md;
        font-weight: 800;
        color: white;
        border: none;
        border-radius: 5px;
        background:#212121;
        text-align: center;
        box-shadow: 0px 0px 3px black;
        transition: all 150ms;
        text-transform: uppercase;
        cursor: pointer;

    &:hover{
      background: white;
      color: black;
      border: none;
    }

  }

      .icon{
        color: #fff5f1;
        font-size: calc(2rem + 1vw);
        margin: 0 auto;
      }


      p{
        color:  #555;
        font-size: $md;
        text-align: center;
      }

    }
  }
   #email{
     @include media($phone-tab){
      grid-row: 0/1;
      grid-column: 1/3;
   }

  }
  .snap-wrap{
    display: block;
    @include position(absolute, 30%, 0);
    width: 100%;
    height: 500px;
    z-index: -1;

  #snap{
    margin: 0;
    width: 100%;
    height: 100%;

    >img{
      width: 100%;
      height: 100%;
    }

  &:after{
     backface-visibility: hidden;
     background: linear-gradient(#000, rgba(0,0,0,0.7), rgba(0,0,0,0.3));
     content: "";
     width: 100%;
     height: 110%;
     top: 0%;
     left: 0;
     right: 0;
     position: absolute;
     z-index: 2;
    }
   }
  }
  >p{
    margin-top: 6%;
    font-size: calc(0.35em + 0.1vw);
    color: #d9d9d9;
    line-height: 1.3;
    z-index: 3;
    a{
      color: #d9d9d9;
    }
     @include media($phone){
     margin-top: 12%;
   }
  }
}
