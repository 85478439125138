#home{
  @include position(relative, 0, 0);
  height: 120vh;
  width: 100%;
  @include media($tab){
    height: 100vh;
  }

  canvas{
    @include position(absolute, 0, 0);
    width:100vw!important;
    height:100vh!important;
    padding:0;
    margin:0;
    cursor:default;
  }

  h1{
    @include position(absolute, 45%, 250%);
    transform: translateY(-50%) ;
    font-size:calc(4.2em + 2.5vw);
    font-weight: 800;
    font-family: 'Quantico', Helvetica, sans-serif;
    color: #050505;
    border: none;
    outline: none;
    opacity: 1;
    width: 100vw;
    line-height: 1.2;
    box-shadow: 1500px 0px 0px inset #050505;
    text-align: center !important;
    text-shadow: none;
    user-select: none;
     @include media($phone){
      font-size: 2.8em;
    }
    @include media($tab){
      top: 54%
    }
  }

  p{
    @include position(absolute, 64%, 50%);
    transform: translate(-50%, -50%)!important;
    opacity: 0;
    font-size: calc(0.6em + 0.7vw);
    color:#050505;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    box-shadow: 0px -400px 0px inset #050505;
    @include media($tab){
      top: 67%
    }
  }

  a{
    text-decoration: none;
    button{
         @include position(absolute, 60%, 0%);
          cursor: pointer;
          scale: 1;
          width: 220px;
          height: auto;
          margin: 2%;
          padding: 2px 7px;
          font-size: calc(0.6em + 0.7vw);
          font-weight: 400;
          color: #444;
          opacity: 0;
          border: none;
          border-radius: 5px;
          background: #111;
          text-align: center;
          letter-spacing: 0.1px;
          text-transform: uppercase;
          transition: all 150ms;
         @include media($phone-tab){
            top: 3%;
            width: 100px;
            background: none;
            color: white;
      }
      &:hover{
        background: white;
        color: #0a0a0a;
        border: none;
      }
    }
  }



  #project-btn{
    left: 10%;
    transform: translate(-50%, -50%)!important;
    padding-left: 90px;
    box-shadow: -30px -10px #070707;
     &:hover{
        box-shadow: 0px -1px 20px white;
        padding: 5px;
        padding-left: 90px;
    }
     @include media($phone-tab){
       left: 15%;
       padding-left:0px !important;
       box-shadow: none;
       animation: opacityAnim 3s ease infinite!important;
    }
  }

  #about-btn{
     left: 85%;
     padding-right: 90px;
     transform: translate(-50%, -50%)!important;
     box-shadow: 30px -10px #070707;
     &:hover{
       box-shadow: 0px -1px 20px white;
       padding: 5px;
       padding-right: 90px;
    }
     @include media($phone-tab){
       padding-right: 0px!important;
       box-shadow: none;
       animation: opacityAnim 3s ease infinite!important;
    }
  }

  .line{
      @include position(absolute, 67%, 0%);
      display: inline-block;
      width: 200px;
      height: 4px;
      border: none;
      opacity: 0;
      box-shadow: 0px 1px 4px inset #111;
     @include media($phone-tab){
       display: none;
    }

     .in-line{
       width: 10px;
       height: 2px;
       box-shadow: 0px 2px inset grey;
     }

    .one{
      margin-left: 3%;
    }
    .two{
      margin-left: 80%;
     }
   }
  #line-0{
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
  }
  #line-1{
     left: 12%;
    transform: translate(-50%, -50%)!important;
  }

  #line-2{
     left: 87%;
    transform: translate(-50%, -50%)!important;
  }
}
