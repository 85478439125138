@import url(https://fonts.googleapis.com/css?family=Karla|Quantico);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: black;
  height: 100%;
  width: 100vw !important;
  font-family: 'Karla', Helvetica, sans-serif;
  font-display: auto;
  overflow-x: hidden;
  cursor: default;
  scroll-behavior: smooth; }

::-webkit-scrollbar {
  display: none; }

#root {
  display: none; }
  #root button:focus {
    outline: 0; }

#loader {
  position: "relative";
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }
  #loader h1 {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #d3d3d3;
    width: 100%;
    text-align: center;
    font-size: calc(1em + 1.7vmax);
    -webkit-animation: opacityAnim 3s ease infinite;
            animation: opacityAnim 3s ease infinite; }
  #loader #wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50%;
    height: 10%;
    -webkit-animation: opacityAnim 1 ease infinite;
            animation: opacityAnim 1 ease infinite; }
    #loader #wrap .cube {
      width: 80px;
      height: 80px;
      -webkit-animation: bounce 2s ease infinite;
              animation: bounce 2s ease infinite; }
      @media only screen and (max-width: 600px) {
        #loader #wrap .cube {
          width: 50px;
          height: 50px; } }
    #loader #wrap #cube1 {
      -webkit-animation-delay: .2s;
              animation-delay: .2s; }
    #loader #wrap #cube2 {
      -webkit-animation-delay: .4s;
              animation-delay: .4s; }
    #loader #wrap #cube3 {
      -webkit-animation-delay: .6s;
              animation-delay: .6s; }

#home {
  position: relative;
  top: 0;
  left: 0;
  height: 120vh;
  width: 100%; }
  @media only screen and (max-width: 800px) and (min-height: 900px) {
    #home {
      height: 100vh; } }
  #home canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    padding: 0;
    margin: 0;
    cursor: default; }
  #home h1 {
    position: absolute;
    top: 45%;
    left: 250%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: calc(4.2em + 2.5vw);
    font-weight: 800;
    font-family: 'Quantico', Helvetica, sans-serif;
    color: #050505;
    border: none;
    outline: none;
    opacity: 1;
    width: 100vw;
    line-height: 1.2;
    box-shadow: 1500px 0px 0px inset #050505;
    text-align: center !important;
    text-shadow: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    @media only screen and (max-width: 600px) {
      #home h1 {
        font-size: 2.8em; } }
    @media only screen and (max-width: 800px) and (min-height: 900px) {
      #home h1 {
        top: 54%; } }
  #home p {
    position: absolute;
    top: 64%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    opacity: 0;
    font-size: calc(0.6em + 0.7vw);
    color: #050505;
    text-align: center;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    box-shadow: 0px -400px 0px inset #050505; }
    @media only screen and (max-width: 800px) and (min-height: 900px) {
      #home p {
        top: 67%; } }
  #home a {
    text-decoration: none; }
    #home a button {
      position: absolute;
      top: 60%;
      left: 0%;
      cursor: pointer;
      scale: 1;
      width: 220px;
      height: auto;
      margin: 2%;
      padding: 2px 7px;
      font-size: calc(0.6em + 0.7vw);
      font-weight: 400;
      color: #444;
      opacity: 0;
      border: none;
      border-radius: 5px;
      background: #111;
      text-align: center;
      letter-spacing: 0.1px;
      text-transform: uppercase;
      -webkit-transition: all 150ms;
      transition: all 150ms; }
      @media only screen and (max-width: 800px) {
        #home a button {
          top: 3%;
          width: 100px;
          background: none;
          color: white; } }
      #home a button:hover {
        background: white;
        color: #0a0a0a;
        border: none; }
  #home #project-btn {
    left: 10%;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    padding-left: 90px;
    box-shadow: -30px -10px #070707; }
    #home #project-btn:hover {
      box-shadow: 0px -1px 20px white;
      padding: 5px;
      padding-left: 90px; }
    @media only screen and (max-width: 800px) {
      #home #project-btn {
        left: 15%;
        padding-left: 0px !important;
        box-shadow: none;
        -webkit-animation: opacityAnim 3s ease infinite !important;
                animation: opacityAnim 3s ease infinite !important; } }
  #home #about-btn {
    left: 85%;
    padding-right: 90px;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    box-shadow: 30px -10px #070707; }
    #home #about-btn:hover {
      box-shadow: 0px -1px 20px white;
      padding: 5px;
      padding-right: 90px; }
    @media only screen and (max-width: 800px) {
      #home #about-btn {
        padding-right: 0px !important;
        box-shadow: none;
        -webkit-animation: opacityAnim 3s ease infinite !important;
                animation: opacityAnim 3s ease infinite !important; } }
  #home .line {
    position: absolute;
    top: 67%;
    left: 0%;
    display: inline-block;
    width: 200px;
    height: 4px;
    border: none;
    opacity: 0;
    box-shadow: 0px 1px 4px inset #111; }
    @media only screen and (max-width: 800px) {
      #home .line {
        display: none; } }
    #home .line .in-line {
      width: 10px;
      height: 2px;
      box-shadow: 0px 2px inset grey; }
    #home .line .one {
      margin-left: 3%; }
    #home .line .two {
      margin-left: 80%; }
  #home #line-0 {
    top: 62%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important; }
  #home #line-1 {
    left: 12%;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important; }
  #home #line-2 {
    left: 87%;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important; }

#projects {
  position: relative;
  top: 0%;
  left: 0%;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 120vh;
  font-size: calc(1.7em + 2vw);
  text-align: center;
  -webkit-transition: visibility 5s ease;
  transition: visibility 5s ease; }
  @media only screen and (max-width: 800px) and (min-height: 900px) {
    #projects {
      height: 100vh; } }
  #projects h3 {
    position: absolute;
    top: -4%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: calc(0.4em + 0.1vw);
    color: #CA7564;
    padding: 10px; }
    @media only screen and (max-width: 800px) {
      #projects h3 {
        top: -6%; } }
  #projects h1 {
    margin: 1%;
    padding: 1%;
    color: #d9d9d9;
    font-weight: 800;
    font-family: 'Quantico', Helvetica, sans-serif;
    line-height: 1; }
  #projects .small-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 1%; }
    #projects .small-icon .icon-s {
      visibility: hidden;
      color: grey;
      font-size: calc(0.5em + 2vw); }
      @media only screen and (max-width: 800px) {
        #projects .small-icon .icon-s {
          visibility: visible;
          cursor: pointer; } }
  #projects p {
    padding: 0 5%;
    font-size: calc(0.4em + 0.1vw);
    color: grey; }
    #projects p .highlight {
      color: #CA7564; }
  #projects .opaque {
    opacity: 1; }
  #projects .transparent {
    opacity: 0.1; }
  #projects .slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: -2% 5%;
    margin-bottom: 0; }
    @media only screen and (max-width: 800px) {
      #projects .slider {
        display: block;
        margin: 2% 0; } }
    @media only screen and (max-width: 800px) and (min-height: 900px) {
      #projects .slider {
        margin: 7% 0 !important; } }
    #projects .slider .arrow {
      width: calc(6.5rem + 3vw);
      height: calc(6.5rem + 3vw);
      border-radius: 50%;
      border: 2px dotted #1a1a1a;
      -webkit-transition: all 100ms ease;
      transition: all 100ms ease;
      -webkit-transition-delay: 0.5s;
              transition-delay: 0.5s;
      cursor: pointer; }
      @media only screen and (max-width: 800px) {
        #projects .slider .arrow {
          display: none; } }
      #projects .slider .arrow .outer-circle {
        margin: 5% auto;
        width: 85%;
        height: 85%;
        border-radius: 50%;
        border: 2px dashed grey;
        -webkit-transition-delay: .2s;
                transition-delay: .2s; }
        #projects .slider .arrow .outer-circle .inner-circle {
          margin: 10% auto;
          width: 80%;
          height: 80%;
          border-radius: 50%;
          border: 2px dotted #1a1a1a;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          #projects .slider .arrow .outer-circle .inner-circle .icon {
            color: grey;
            font-size: calc(0.3em + 2vw); }
            @media only screen and (max-width: 800px) {
              #projects .slider .arrow .outer-circle .inner-circle .icon {
                display: none; } }
      #projects .slider .arrow:hover {
        box-shadow: 0px 0px 7px 5px inset white, 0px 0px 50px #CA7564;
        border-color: transparent; }
        #projects .slider .arrow:hover .outer-circle {
          border-color: transparent; }
        #projects .slider .arrow:hover .inner-circle {
          border-color: transparent; }
          #projects .slider .arrow:hover .inner-circle .icon {
            color: white; }
    #projects .slider .inner {
      position: relative;
      top: 0;
      left: 0;
      border-radius: 0%;
      border: none;
      width: 647px;
      height: 366px;
      overflow: visible;
      background: transparent;
      -webkit-mask-image: -webkit-radial-gradient(black 30%, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.1));
      -webkit-mask-size: cover;
      mask-image: radial-gradient(black 30%, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.1));
      mask-size: cover;
      -webkit-mask-position: center;
              mask-position: center;
      mask-type: alpha; }
      @media only screen and (max-width: 800px) {
        #projects .slider .inner {
          width: 90vw;
          height: auto;
          margin: 0 auto; } }
      #projects .slider .inner img {
        border-radius: 5px;
        border: none;
        width: 100%;
        height: 100%;
        box-shadow: none; }
      #projects .slider .inner .mask {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px inset #CA7564;
        z-index: 999; }
      #projects .slider .inner .graph {
        position: absolute;
        bottom: -1px;
        left: 0px;
        width: 102%;
        height: 45%;
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0.9), #000);
        background: linear-gradient(rgba(0, 0, 0, 0.9), #000);
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        #projects .slider .inner .graph .bar {
          width: 90%;
          height: 10%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 0.2px solid black;
          z-index: 999;
          color: white;
          margin: 2% 3%; }
          #projects .slider .inner .graph .bar p {
            font-weight: 500;
            text-transform: uppercase; }
          #projects .slider .inner .graph .bar .shadow {
            height: 5px;
            width: 450px;
            background: black;
            border-width: 0.2px 0px;
            margin-left: 7%; }
  #projects #buttons {
    cursor: pointer;
    margin-top: -2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 800px) {
      #projects #buttons {
        margin-top: 0; } }
    @media only screen and (max-width: 800px) and (min-height: 900px) {
      #projects #buttons {
        margin-top: 2%; } }
    #projects #buttons a {
      margin: 0 2%; }
    #projects #buttons .button {
      width: 100%;
      height: auto;
      padding: 12px 24px;
      font-size: calc(0.4em + 0.1vw);
      color: white;
      cursor: pointer;
      border: 1px solid #CA7564;
      margin: 2%;
      box-sizing: border-box;
      background: transparent;
      text-align: center;
      text-transform: uppercase;
      font-weight: 800;
      box-shadow: none;
      border-radius: 5px;
      -webkit-transition: all 150ms;
      transition: all 150ms; }
      @media only screen and (max-width: 600px) {
        #projects #buttons .button {
          padding: 12px 12px; } }
      #projects #buttons .button:hover {
        background: white;
        color: black;
        box-shadow: 0px 5px 25px #CA7564; }

#about {
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 20vh;
  font-size: calc(1.7em + 2vw); }
  #about h3 {
    font-size: calc(0.4em + 0.1vw);
    color: #CA7564;
    margin-top: 4%;
    padding: 0 !important; }
    @media only screen and (max-width: 800px) and (min-height: 900px) {
      #about h3 {
        margin-top: 12%;
        margin-left: 0% !important; } }
  #about h1 {
    margin-top: -2.5% !important;
    padding: 0 !important;
    font-weight: 800;
    color: #d9d9d9;
    font-family: 'Quantico', Helvetica, sans-serif; }
    @media only screen and (max-width: 800px) and (min-height: 900px) {
      #about h1 {
        margin-left: 0% !important; } }
  #about .icon {
    position: absolute;
    color: #FFF5F1;
    opacity: 0.3;
    visibility: visible;
    -webkit-transform: scale(0.2) rotateX(30deg) rotateY(18deg) rotateZ(-24deg) translate(100px, -20px);
            transform: scale(0.2) rotateX(30deg) rotateY(18deg) rotateZ(-24deg) translate(100px, -20px);
    font-size: calc(13px + 2vmax);
    box-shadow: -70px 122px 170px 0 #FFF;
    border-radius: 50%;
    z-index: -1; }
    @media only screen and (max-width: 600px) {
      #about .icon {
        font-size: 24px;
        opacity: 0.2 !important; } }
    @media only screen and (min-width: 400px) and (max-width: 840px) and (max-height: 1300px) {
      #about .icon {
        opacity: 0.2 !important; } }
  #about .i-1 {
    position: absolute;
    top: 50%;
    left: 5%; }
  #about .i-2 {
    position: absolute;
    top: 52%;
    left: 20%; }
  #about .i-3 {
    position: absolute;
    top: 68%;
    left: 30%; }
    @media only screen and (min-width: 400px) and (max-width: 840px) and (max-height: 1300px) {
      #about .i-3 {
        top: 50%; } }
  #about .i-4 {
    position: absolute;
    top: 55%;
    left: 4%; }
    @media only screen and (min-width: 400px) and (max-width: 840px) and (max-height: 1300px) {
      #about .i-4 {
        top: 45%; } }
  #about .i-5 {
    position: absolute;
    top: 60%;
    left: 15%; }
  #about .i-6 {
    top: 52%;
    right: 20%; }
  #about .i-7 {
    top: 60%;
    right: 15%; }
  #about .i-8 {
    top: 68%;
    right: 30%; }
    @media only screen and (min-width: 400px) and (max-width: 840px) and (max-height: 1300px) {
      #about .i-8 {
        top: 50%; } }
  #about .i-9 {
    top: 55%;
    right: 4%; }
    @media only screen and (min-width: 400px) and (max-width: 840px) and (max-height: 1300px) {
      #about .i-9 {
        top: 45%; } }
  #about .i-10 {
    top: 50%;
    right: 4%; }
  #about .i-11 {
    top: 63%;
    right: 48%; }
    @media only screen and (min-width: 400px) and (max-width: 840px) and (max-height: 1300px) {
      #about .i-11 {
        top: 48%; } }
  #about .hidden {
    display: none; }
  #about .visible {
    display: block; }
  #about .information {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    #about .information .widget-icons {
      margin-top: 1%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around; }
      #about .information .widget-icons #title-circle {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: solid 1px #CA7564;
        box-shadow: inset 0px 1px 5px #CD7635, 0px 5px 25px white; }
      #about .information .widget-icons .line {
        width: 200px;
        height: 10px;
        border: none;
        box-shadow: 0px 1px 10px inset #111; }
        @media only screen and (max-width: 800px) {
          #about .information .widget-icons .line {
            display: none; } }
        #about .information .widget-icons .line .in-line {
          width: 10px;
          height: 2px;
          box-shadow: 0px 2px inset grey; }
        #about .information .widget-icons .line .one {
          margin-left: 3%; }
        #about .information .widget-icons .line .two {
          margin-left: 80%; }
    #about .information #display-card {
      background: #0a0a0a;
      height: auto;
      width: 100vw; }
      #about .information #display-card .content {
        text-align: center;
        padding: 2%; }
        #about .information #display-card .content #intro p {
          font-size: calc(0.4em + 0.1vw);
          color: #d3d3d3; }
        #about .information #display-card .content #skills .row {
          display: flex;
          flex-wrap: wrap;
          flex: 100px 1 1;
          justify-content: center; }
        #about .information #display-card .content #skills .card {
          width: auto;
          height: auto;
          padding: 2px 15px;
          margin: 1% 0.2%;
          border: 0.5px solid #d9d9d9; }
          @media only screen and (max-width: 800px) {
            #about .information #display-card .content #skills .card {
              margin: 1% 0; } }
          #about .information #display-card .content #skills .card h3 {
            font-size: calc(0.3em + 0.1vw);
            color: white;
            margin-left: 0; }
          #about .information #display-card .content #skills .card p {
            font-size: calc(0.3em + 0.1vw);
            color: #d3d3d3; }
          #about .information #display-card .content #skills .card hr {
            margin: 2% auto;
            margin-bottom: 1%;
            border: none;
            height: 1px;
            width: 100%;
            box-shadow: 0px 1px #CA7564; }
            @media only screen and (max-width: 600px) {
              #about .information #display-card .content #skills .card hr {
                width: 40%; } }
        #about .information #display-card .content .button {
          width: 100%;
          height: auto;
          padding: 12px 24px;
          font-size: calc(0.4em + 0.1vw);
          color: white;
          cursor: pointer;
          border: 1px solid #CA7564;
          box-sizing: border-box;
          background: transparent;
          text-align: center;
          text-transform: uppercase;
          font-weight: 800;
          box-shadow: none;
          border-radius: 5px;
          -webkit-transition: all 150ms;
          transition: all 150ms; }
          @media only screen and (max-width: 600px) {
            #about .information #display-card .content .button {
              padding: 12px 12px; } }
          #about .information #display-card .content .button:hover {
            background: white;
            color: black;
            box-shadow: 0px 5px 25px #CA7564; }
  #about .text-underline-red {
    position: relative; }
  #about .text-underline-red:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    top: 100%;
    left: 0;
    background: #ff4f00;
    border-radius: 3px;
    display: block; }

#contact {
  position: relative;
  top: 0%;
  left: 0%;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 90vh;
  text-align: center;
  -webkit-transform: translate3d(0, 100, 0);
          transform: translate3d(0, 100, 0);
  font-size: calc(1.7em + 2vw); }
  @media only screen and (max-width: 800px) and (min-height: 900px) {
    #contact {
      height: 100vh; } }
  #contact h3 {
    font-size: calc(0.4em + 0.1vw);
    color: #CA7564;
    margin-top: 5%;
    padding: 0; }
  #contact h1 {
    margin: -1%;
    padding: 0;
    font-weight: 800;
    color: #d9d9d9;
    font-family: 'Quantico', Helvetica, sans-serif; }
  #contact .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: calc(13% + 6vw); }
    @media only screen and (max-width: 800px) and (min-height: 900px) {
      #contact .wrapper {
        margin-top: 25%; } }
    @media only screen and (max-width: 800px) {
      #contact .wrapper {
        margin-top: 25% !important;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 0px 0px; } }
    #contact .wrapper .card {
      width: 480px;
      height: auto;
      background: rgba(0, 0, 0, 0.8);
      padding: 20px 0px;
      border: 0.5px solid white;
      z-index: 999 !important;
      text-align: center !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: no-wrap;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
      @media only screen and (max-width: 800px) {
        #contact .wrapper .card {
          width: auto;
          background: rgba(0, 0, 0, 0.5); } }
      #contact .wrapper .card > a button {
        width: 99%;
        height: auto;
        margin: 0 0;
        padding: 20px;
        font-size: calc(0.4em + 0.1vw);
        font-weight: 800;
        color: white;
        border: none;
        border-radius: 5px;
        background: #212121;
        text-align: center;
        box-shadow: 0px 0px 3px black;
        -webkit-transition: all 150ms;
        transition: all 150ms;
        text-transform: uppercase;
        cursor: pointer; }
        #contact .wrapper .card > a button:hover {
          background: white;
          color: black;
          border: none; }
      #contact .wrapper .card .icon {
        color: #fff5f1;
        font-size: calc(2rem + 1vw);
        margin: 0 auto; }
      #contact .wrapper .card p {
        color: #555;
        font-size: calc(0.4em + 0.1vw);
        text-align: center; }
  @media only screen and (max-width: 800px) {
    #contact #email {
      grid-row: 0/1;
      grid-column: 1/3; } }
  #contact .snap-wrap {
    display: block;
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: -1; }
    #contact .snap-wrap #snap {
      margin: 0;
      width: 100%;
      height: 100%; }
      #contact .snap-wrap #snap > img {
        width: 100%;
        height: 100%; }
      #contact .snap-wrap #snap:after {
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        background: -webkit-linear-gradient(#000, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
        background: linear-gradient(#000, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
        content: "";
        width: 100%;
        height: 110%;
        top: 0%;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 2; }
  #contact > p {
    margin-top: 6%;
    font-size: calc(0.35em + 0.1vw);
    color: #d9d9d9;
    line-height: 1.3;
    z-index: 3; }
    #contact > p a {
      color: #d9d9d9; }
    @media only screen and (max-width: 600px) {
      #contact > p {
        margin-top: 12%; } }

.anim {
  -webkit-transform: translateY(150px);
          transform: translateY(150px); }

.come-in {
  -webkit-animation: come-in 1s ease-out forwards;
          animation: come-in 1s ease-out forwards; }

@-webkit-keyframes opacityAnim {
  0% {
    opacity: .5; }
  50% {
    opacity: .25; }
  100% {
    opacity: .5; } }

@keyframes opacityAnim {
  0% {
    opacity: .5; }
  50% {
    opacity: .25; }
  100% {
    opacity: .5; } }

.bounce {
  -webkit-animation: bounceFaster 13s ease infinite;
          animation: bounceFaster 13s ease infinite; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes bounceFaster {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  80% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes bounceFaster {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  80% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes come-in {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes come-in {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

body {
  margin: 0;
  padding: 0; }

