body{
  background: black;
  height: 100%;
  width: 100vw!important;
  font-family: 'Karla', Helvetica, sans-serif;
  font-display: auto;
  overflow-x: hidden;
  cursor: default;
  scroll-behavior: smooth;
}

::-webkit-scrollbar { //hide scrollbar for chrome and safari
    display: none;
}

#root{
  display: none;//while loading
  button:focus {
    outline:0;
  }
}
